<template>
  <div class="flex items-center min-h-screen font-montserrat bg-gray-50 dark:bg-gray-900">
    <div class="container mx-auto">
      <div class="lg:w-1/2 w-full mx-auto my-10 bg-color p-5 rounded-md shadow-sm">
        <div class="text-center">
          <h1
            class="my-3 text-3xl font-semibold text-white font-4xl dark:text-gray-200"
          >
            Keep your account safe
          </h1>
          <p class="text-white text-lg dark:text-gray-400">
            We've sent a verification code to your phone ending  1234
          </p>
        </div>
        <div class="m-7 ">
            <p class="text-lg text-white">Verification Code</p>
          <div
            id="otp"
            class="flex flex-row justify-center text-center px-2 "
          >
          
            <input
              class="m-2 border h-10 w-10 text-center form-control rounded"
              type="text"
              id="first"
              maxlength="1"
            />
            <input
              class="m-2 border h-10 w-10 text-center form-control rounded"
              type="text"
              id="second"
              maxlength="1"
            />
            <input
              class="m-2 border h-10 w-10 text-center form-control rounded"
              type="text"
              id="third"
              maxlength="1"
            />
            <input
              class="m-2 border h-10 w-10 text-center form-control rounded"
              type="text"
              id="fourth"
              maxlength="1"
            />
            <input
              class="m-2 border h-10 w-10 text-center form-control rounded"
              type="text"
              id="fifth"
              maxlength="1"
            />
           
          </div>
         <div class="space-y-3 my-5">
               <p class=" font-semibold text-oonpaysecondary text-2xl">Send new Code</p>
          <p class="text-lg text-white py-4">Keep device verified for 30 days</p>
          <a href="#" class="text-2xl  text-oonpaysecondary underline mt-2">Change number</a>
         </div>
        <button  @click="fundOption" class="btn btn-success btn-block">Verify</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    methods:{
        fundOption(){
            this.$router.push("/crowdhub-dashboard")
        }
    }
};
</script>

<style scoped>
.bg-color{
  background: #0c4369 !important;
}

label{
  color: #fff;
}

.btn-success{
  background: #f15825 !important;
  border: none;
}
</style>